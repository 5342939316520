<template>
    <RawWrap width="auto" :class="[{ 'is-empty': mInvoices.length === 0 }]">
        <div class="Invoice-Wrap" v-for="(invoice, i) in mInvoices" :key="i">
            <h3 style="font-weight:bold;margin:20px 0 5px 4px">{{ invoice.year }}</h3>

            <Row
                v-if="invoice.items.length > 0"
                :parentHover="false"
                :items="{ row: header }"
                :key="-1"
                :index="-1"
                class="Row-Header"
            />
            <Row
                v-for="(invoice, i) in invoice.items"
                :key="invoice.invoiceNumber"
                :parentHover="false"
                :hasClick="true"
                :items="invoice"
                :index="i"
                @onClick="handleInvoiceClick"
            />
        </div>

        <EmptyState v-if="mInvoices.length === 0" />
    </RawWrap>
</template>

<script type="text/javascript">
import Row from '@/components/Row';
import Headline from '@/components/Headline';

import { format } from 'date-fns';
import { priceToEuroString, round2d } from '@/lib/helper';
import EmptyState from '@/components/EmptyState.vue';
import RawWrap from '@/components/RowWrap.vue';

export default {
    name: 'ItemTable',
    components: {
        RawWrap,
        Row,
        Headline,
        EmptyState,
    },
    props: {
        invoices: {
            type: Array,
        },
    },
    data: () => {
        return {
            activeRow: -1,
            activeBar: -1,
        };
    },
    computed: {
        header() {
            return [
                { name: 'Rechnungs Nr.', value: null },
                { name: 'Datum', value: null },
                { name: 'Status', value: null },
                { name: 'Brutto', value: null },
                { name: 'Download', value: null },
            ];
        },
        mInvoices() {
            const invoices = this.formatInvoices(this.invoices);
            return invoices;
        },
    },
    methods: {
        handleInvoiceClick(p) {
            const target = p.event.target;
            const isCheckbox = target.classList.contains('Checkbox') || target.type === 'checkbox';
            if (isCheckbox) return;
            this.$emit('onInvoiceClick', p.id);
        },
        formatInvoices(invoices) {
            const formatted = invoices
                .reduce((acc, invoice) => {
                    const year = format(new Date(invoice.date), 'yyyy');
                    const index = acc.findIndex(i => i.year === year);
                    const item = this.formatItem(invoice);
                    if (index === -1) {
                        acc.push({ year, items: [item] });
                    } else {
                        acc[index].items = [...acc[index].items, item].sort(
                            (a, b) =>
                                b.id.replace('WP', '').replaceAll('/', '') -
                                a.id.replace('WP', '').replaceAll('/', ''),
                        );
                    }
                    return acc;
                }, [])
                .sort((a, b) => b.year - a.year);
            return formatted;
        },
        formatItem(invoice) {
            return {
                id: invoice.invoiceNumber,
                row: [
                    { name: 'Rechnungs Nr.', value: invoice.invoiceNumber || '' },
                    {
                        name: 'Datum',
                        value: format(new Date(invoice.date), 'dd.MM.yyyy'),
                    },
                    {
                        name: 'Status',
                        value: invoice.isPaid ? 'Bezahlt' : 'Offen',
                    },
                    {
                        name: 'Brutto',
                        value: priceToEuroString(invoice.totalAmount),
                    },

                    {
                        name: 'Download',
                        props: {
                            size: 'small',
                            id: invoice.invoiceNumber,
                            type: 'pdf',
                            title: 'Rechnung',
                            onDownload: () => {
                                const item = this.mInvoices.reduce((acc, { items }) => {
                                    const item = items.find(
                                        ({ id }) => id == invoice.invoiceNumber,
                                    );
                                    if (item) {
                                        acc = item;
                                    }
                                    return acc;
                                }, {});
                            },
                        },
                        component: 'Download',
                    },
                ],
            };
        },
    },
};
</script>
